import { Box, Chip, Grid, Stack, Typography } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { getPhoneCode } from "libphonenumber-js";
import CountryCodeIcon from "../../Common/CountryCodeIcon";
import Input from "../../Common/Input";
import StyledTextarea from "../../Common/StyledTextarea";
import { Fragment } from "react";
import { WhatsApp } from "@mui/icons-material";

export const SupplierViewComponent = ({
    formType,
    supplierData,
    showProfileUpdate = false,
    handleChange,
    values,
    status,
}) => {
    if (formType !== "view") return null;
    const phoneCode = supplierData?.country_code
        ? getPhoneCode(supplierData?.country_code)
        : "";

    return (
        <Stack gap={2} flexDirection={"column"} display={"flex"}>
            {/* Business Info */}
            <Grid container spacing={2}>
                <Grid item xs={status ? 6 : 12}>
                    <Box
                        sx={{
                            borderColor: "#EDEDED",
                            borderWidth: "1px",
                            borderStyle: "solid",
                            display: "flex",
                            justifyContent: "space-between",
                            p: 2,
                            borderRadius: "8px",
                            alignItems: "flex-start",
                        }}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 1,
                            }}>
                            <Typography variant="h6" sx={{ fontSize: 20 }}>
                                {supplierData?.user?.name}
                            </Typography>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                    color: "#131313",
                                }}>
                                <MailOutlineIcon />
                                <Typography variant="body2">
                                    {supplierData?.email}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                    color: "#131313",
                                }}>
                                <PhoneInTalkIcon fontSize="small" />
                                <Typography variant="body2">
                                    +{phoneCode} {supplierData?.mobile_number}
                                </Typography>
                                {supplierData?.is_whatsapp ? (
                                    <WhatsApp sx={{ fill: "#075e54" }} />
                                ) : null}
                            </Box>
                        </Box>
                        <Box>
                            <Typography
                                variant="subtitle1"
                                sx={{
                                    display: "flex",
                                    direction: "row",
                                    alignItems: "center",
                                    gap: 0,
                                }}>
                                <CountryCodeIcon
                                    countryCode={supplierData?.country_code}
                                />
                                {supplierData?.business_location}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                {status && (
                    <Grid item xs={6}>
                        <Box
                            sx={{
                                borderColor: "#EDEDED",
                                borderWidth: "1px",
                                borderStyle: "solid",
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                p: 2,
                                borderRadius: "8px",
                                alignItems: "flex-start",
                                height: "100%",
                            }}>
                            <Typography variant="h6" sx={{ fontSize: 20 }}>
                                {supplierData?.legal_name}
                            </Typography>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                    color: "#131313",
                                }}>
                                <Typography variant="body2">
                                    {supplierData?.business_type}
                                </Typography>
                                <Chip
                                    label={
                                        supplierData?.business_customize_requirement
                                            ? "Custom"
                                            : "Standard"
                                    }
                                    color="primary"
                                    variant="contained"
                                    size="small"
                                />
                            </Box>
                        </Box>
                    </Grid>
                )}
            </Grid>

            {(status === "active" || status === "disabled" || !status) && (
                <Grid
                    sx={{
                        borderColor: "#EDEDED",
                        borderWidth: "1px",
                        borderStyle: "solid",
                        m: 0,
                        width: "100%",
                        borderRadius: "8px",
                        pb: 2,
                    }}
                    container
                    spacing={2}>
                    {!status && (
                        <Grid
                            item
                            xs={12}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 1,
                            }}>
                            <Typography variant="h6" sx={{ fontSize: 20 }}>
                                {supplierData?.legal_name}
                            </Typography>
                            {/*   <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                    color: "#131313",
                                }}>
                                <Typography variant="body2">
                                    {supplierData?.business_type}
                                </Typography>
                                <Chip
                                    label={
                                        supplierData?.business_customize_requirement
                                            ? "Custom"
                                            : "Standard"
                                    }
                                    color="primary"
                                    variant="contained"
                                    size="small"
                                />
                            </Box> */}
                        </Grid>
                    )}

                    {/* GSTIN & Certificate */}
                    <Grid
                        item
                        xs={12}
                        md={4}
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 0.8,
                        }}>
                        <Typography
                            variant="body2"
                            sx={{ color: "#767676", fontSize: "12px" }}>
                            GSTIN & Certificate
                        </Typography>
                        <Typography variant="body2" sx={{ color: "#131313" }}>
                            {supplierData?.gst_vat_number}
                        </Typography>
                        <Stack
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                width: "100%",
                                overflowX: "auto",
                                gap: 1,
                            }}>
                            <FileBox
                                files={
                                    supplierData?.file_mappings
                                        ? [
                                              supplierData?.file_mappings.find(
                                                  (file) =>
                                                      file.file_type ===
                                                      "gst_vat_file"
                                              ),
                                          ]
                                        : null
                                }
                            />
                        </Stack>
                    </Grid>
                </Grid>
            )}

            {/* Reason based on status */}
            {(supplierData?.approval_status === "rejected" ||
                supplierData?.approval_status === "suspended") && (
                <Grid
                    sx={{
                        borderColor: "#EDEDED",
                        borderWidth: "1px",
                        borderStyle: "solid",
                        m: 0,
                        width: "100%",
                        borderRadius: "8px",
                        pb: 2,
                    }}
                    container
                    spacing={2}>
                    <Grid
                        item
                        xs={12}
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 0.8,
                            width: { md: "600px", xs: "400px", sm: "300px" },
                        }}>
                        <Typography
                            variant="body2"
                            sx={{ color: "#767676", fontSize: "12px" }}>
                            {supplierData?.approval_status === "rejected"
                                ? "Rejected Reason"
                                : "Suspend Reason"}
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            sx={{ color: "#131313" }}>
                            {supplierData?.approval_status === "rejected"
                                ? supplierData?.reject_reason || "N/A"
                                : supplierData?.suspend_reason || "N/A"}
                        </Typography>
                    </Grid>
                </Grid>
            )}
        </Stack>
    );
};

const FileBox = ({ files }) => {
    console.log(files, "files");

    if (!files || files?.length === 0) return null;

    return (
        <Fragment>
            {files.map((file, index) => (
                <Box
                    key={index}
                    sx={{
                        width: "210px",
                        height: "71px",
                        borderRadius: "8px",
                        borderWidth: "1px",
                        borderStyle: "solid",
                        borderColor: "#D6D6D6",
                        padding: "16px",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        display: "flex",
                        cursor: "pointer",
                    }}
                    onClick={() => window.open(file.file_url, "_blank")}>
                    <InsertDriveFileIcon
                        style={{
                            color: "#131313",
                            fill: "#fff",
                            stroke: "#131313",
                        }}
                    />
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 0.5,
                            ml: 1,
                        }}>
                        <Typography
                            variant="body2"
                            sx={{ color: "#2B2B2B", fontSize: "14px" }}>
                            {file?.file_url?.split("/").pop()}
                        </Typography>
                        {/* Assuming file size is known or can be extracted, you might add it here */}
                    </Box>
                </Box>
            ))}
        </Fragment>
    );
};
