import { Box, Checkbox, Chip, FormControl, FormControlLabel, FormLabel, Grid, InputAdornment, InputLabel, Radio, RadioGroup, Stack, TextField, Typography } from "@mui/material";
import ReactFlagsSelect from "react-flags-select";
import Input from "../../Common/Input";
import CountryCodeIcon from "../../Common/CountryCodeIcon";
import { SearchOutlined } from "@mui/icons-material";

export const SupplierAddComponent = ({ formType, handleChange, setFieldValue, errors, values }) => {
    if (formType !== "edit") return null;

    console.log("values", values);
    

    return (
        <Grid container spacing={2} >
            <Grid item xs={12} md={6}>
                <Input
                    id='name'
                    name="name"
                    label="Name"
                    placeholder="Enter Name Name"
                    onChange={handleChange("name")}
                    value={values?.name || ""}
                    error={Boolean(errors.name)}
                    helperText={errors.name}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "6px" }}>
                    <InputLabel sx={{ fontSize: "14px", color: "#454545", fontWeight: "500" }} shrink={false}>Country</InputLabel>
                    <ReactFlagsSelect
                        className="menu-flags custom-scroll-style"
                        selected={values?.country_code || ""}
                        searchPlaceholder="Search "
                        placeholder="Select the Country"
                        searchable
                        onSelect={(countryCode) => setFieldValue("country_code", countryCode)}
                    />
                </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "6px" }}>
                    <InputLabel sx={{ fontSize: "14px", color: "#454545", fontWeight: "500" }} shrink={false}>Mobile Number</InputLabel>
                    <TextField
                        type="number"
                        name="mobile_number"
                        size="small"
                        id="outlined-search"
                        value={values.mobile_number}
                        style={{ minWidth: 300 }}
                        onChange={handleChange("mobile_number")}
                        error={Boolean(errors.mobile_number)}
                        helperText={errors.mobile_number}
                        fullWidth
                        InputProps={{
                            startAdornment: values?.country_code ? <CountryCodeIcon countryCode={values?.country_code} showNumber={true} /> : null,
                        }}
                    />
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Checkbox
                            checked={values?.is_whatsapp}
                            onChange={handleChange("is_whatsapp}")}
                            name="is_whatsapp}"
                            color="primary"
                        />
                        <Typography variant="body2">This is my whatsapp number</Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} md={12}>
                <Input
                    id='legal_name'
                    name="legal_name"
                    label="Legal Name of Business as per Govt. Records"
                    placeholder="Enter Legal Name"
                    onChange={handleChange("legal_name")}
                    value={values?.legal_name || ""}
                    error={Boolean(errors.legal_name)}
                    helperText={errors.legal_name}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} md={12} >
                <Box sx={{ width: "50%", display: "flex", flexDirection: "column", gap: "0px" }}>
                    <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">Nature of Business</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            sx={{ width: "100%" }}
                            value={values?.business_type}
                            onChange={handleChange("business_type")}
                        >
                            <FormControlLabel
                                sx={{ width: "100%", borderWidth: "1px", borderStyle: "solid", borderColor: "#EDEDED", padding: "10px", m: 0, borderTopRightRadius: "10px", borderTopLeftRadius: "10px" }}
                                value="Wholesale Supplier of Packaging Materials"
                                control={<Radio />}
                                label="Wholesale Supplier of Packaging Materials"
                                onChange={handleChange("business_type")}
                            />
                            <FormControlLabel
                                sx={{ width: "100%", borderWidth: "1px", borderStyle: "solid", borderColor: "#EDEDED", padding: "10px", m: 0, borderBottomRightRadius: "10px", borderBottomLeftRadius: "10px" }}
                                value="Manufacturing of Packaging Materials"
                                control={<Radio />}
                                label="Manufacturing of Packaging Materials"
                                onChange={handleChange("business_type")}
                            />
                        </RadioGroup>
                    </FormControl>
                </Box>
            </Grid>
            {
                values?.business_type === "Manufacturing of Packaging Materials" && (
                    <Grid item xs={12} md={12} >
                        <Box sx={{ width: "50%", display: "flex", flexDirection: "column", gap: "0px" }}>
                            <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label">Customize as per customer requirements</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    sx={{ width: "100%" }}
                                    value={values?.business_customize_requirement}
                                    onChange={handleChange("business_customize_requirement")}
                                >
                                    <FormControlLabel
                                        value="yes"
                                        control={<Radio />}
                                        label="Yes"
                                        onChange={handleChange("business_customize_requirement")}
                                    />
                                    <FormControlLabel
                                        value="no"
                                        control={<Radio />}
                                        label="No"
                                        onChange={handleChange("business_customize_requirement")}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Box>
                    </Grid>
                )
            }
        </Grid>
    )
}