import {
    Box,
    Checkbox,
    Chip,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    InputAdornment,
    InputLabel,
    Radio,
    RadioGroup,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import ReactFlagsSelect from "react-flags-select";
import Input from "../../Common/Input";
import CountryCodeIcon from "../../Common/CountryCodeIcon";
import { SearchOutlined } from "@mui/icons-material";

export const SupplierAddComponent = ({
    formType,
    handleChange,
    setFieldValue,
    errors,
    values,
}) => {
    if (formType !== "edit") return null;

    console.log("values", values);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <Input
                    id="name"
                    name="name"
                    label="Name"
                    placeholder="Enter Name Name"
                    onChange={handleChange("name")}
                    value={values?.name || ""}
                    error={Boolean(errors.name)}
                    helperText={errors.name}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "6px",
                    }}>
                    <InputLabel
                        sx={{
                            fontSize: "14px",
                            color: "#454545",
                            fontWeight: "500",
                        }}
                        shrink={false}>
                        Country
                    </InputLabel>
                    <ReactFlagsSelect
                        className="menu-flags custom-scroll-style"
                        selected={values?.country_code || ""}
                        searchPlaceholder="Search "
                        placeholder="Select the Country"
                        searchable
                        onSelect={(countryCode) =>
                            setFieldValue("country_code", countryCode)
                        }
                    />
                </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "6px",
                    }}>
                    <InputLabel
                        sx={{
                            fontSize: "14px",
                            color: "#454545",
                            fontWeight: "500",
                        }}
                        shrink={false}>
                        Mobile Number
                    </InputLabel>
                    <TextField
                        type="number"
                        name="mobile_number"
                        size="small"
                        id="outlined-search"
                        value={values.mobile_number}
                        style={{ minWidth: 300 }}
                        onChange={handleChange("mobile_number")}
                        error={Boolean(errors.mobile_number)}
                        helperText={errors.mobile_number}
                        fullWidth
                        InputProps={{
                            startAdornment: values?.country_code ? (
                                <CountryCodeIcon
                                    countryCode={values?.country_code}
                                    showNumber={true}
                                />
                            ) : null,
                        }}
                    />
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Checkbox
                            checked={values?.is_whatsapp}
                            onChange={handleChange("is_whatsapp}")}
                            name="is_whatsapp}"
                            color="primary"
                        />
                        <Typography variant="body2">
                            This is my whatsapp number
                        </Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} md={12}>
                <Input
                    id="legal_name"
                    name="legal_name"
                    label="Legal Name of Business as per Govt. Records"
                    placeholder="Enter Legal Name"
                    onChange={handleChange("legal_name")}
                    value={values?.legal_name || ""}
                    error={Boolean(errors.legal_name)}
                    helperText={errors.legal_name}
                    fullWidth
                />
            </Grid>
        </Grid>
    );
};
