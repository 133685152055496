export const baseUrl = process.env.NODE_ENV === "development" ? process.env.REACT_APP_DEV_API_BASE_URL : process.env.REACT_APP_PROD_API_BASE_URL;

// auth urls only
export const sendOtpUrl = "/auth/send-otp";
export const verifyOtpUrl = "/auth/verify-login-otp";
export const resendOtpUrl = "/auth/resend-otp";


export const drawerWidth = 260;

export const fileTypes = ["jpeg", "jpg", "png", "gif"];
export const videoTypes = ["mp4"];

export const defaultPaginate = {
    pageNo: 0,
    limit: 10,
    sortBy: "DESC",
    sortField: "createdAt",
    keywords: ""
}

export const daysArray = [
    { value: "Monday", label: "Monday" },
    { value: "Tuesday", label: "Tuesday" },
    { value: "Wednesday", label: "Wednesday" },
    { value: "Thursday", label: "Thursday" },
    { value: "Friday", label: "Friday" },
    { value: "Saturday", label: "Saturday" },
    { value: "Sunday", label: "Sunday" }
]

export const ROLES = {
    ADMIN: "admin",
    SUBADMIN: "subadmin",
    SUPPLIER: "supplier",
    BUYER: "buyer",
    USER: "user",
}

export const fetchSupplierStatus = {
    "new": "pending",
    "active": "approved",
    "profile": "approved",
    "suspended": "suspended",
    "rejected": "rejected"
}

export const getStatusCamelName = {
    "pending": "Pending",
    "active": "Active",
}

export const statusContent = {
    "approved": {
        title: "Approved Successfully",
        subtitle: "Supplier Id supplier_id is approved and moved to active suppliers"
    }
}